"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postMessageToIframe = void 0;
const postMessageToIframe = ({
  iframe,
  iframeSrc,
  type,
  data
}) => {
  var _iframe$contentWindow;
  iframe === null || iframe === void 0 || (_iframe$contentWindow = iframe.contentWindow) === null || _iframe$contentWindow === void 0 || _iframe$contentWindow.postMessage(JSON.stringify({
    type,
    data
  }), iframeSrc);
};
exports.postMessageToIframe = postMessageToIframe;